@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
  --darkgray: #212329;
  --lightgray: #EFEFEF;
  --linear-gradient: linear-gradient(to right, #DFDFDF, #F4F4F4);
  --success: #4BB543;
  --lightgreen: #00B258;
  --red: #C40000;
}



html { scroll-behavior: smooth;}

*, body { font-family: 'Inter', sans-serif; }

h1, h2, h3, h4, h5, h6 { color: var(--darkgray); }

// Variables
@import 'variables';


ul.alert {
    list-style-type: none;
}

.navbar{
    padding-top: 0;
    padding-bottom: 0;
}

.nav-pills .nav-link, a.nav-link{
    padding: 20px !important;
    border-radius: 0;
    color: rgba(0, 0, 0, 0.55);
}

.nav-pills .nav-link.active, .nav-pills .nav-link:hover, a.nav-link:hover{
    background: #ee3e3e;
    color: #ffffff !important;
}

.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 980px;
}

dl { width: 100% }
dt { float: left; width: 100%; overflow: hidden; white-space: nowrap }
dd { float: left; width: 100%; overflow: hidden }

dt span:after { content: " ......................................................................................................................................................................................................................................................" }

/* laravel + tailwind 09/11 */

.title-color { @apply text-gray-700; }

/* effects not applyed with tailwind */
.input__login,
.input__label,
.input__icon,
.menu__item::before,
.circuito__informacoes,
.botao__fatura--icone,
.offcanvas__recebidas,
.offcanvas__efetuadas,
.navigator__menu { transition: all 350ms ease; }
/* effects not applyed with tailwind */

/* login form animation */
.input__login:focus ~ .input__label,
.input__login:not(:placeholder-shown) ~ .input__label { transform: translate(-1.8rem, -1.8rem); }

.input__login:focus ~ .input__icon { transform: rotateY(180deg); }

.login__client:not(:placeholder-shown):valid,
.login__client:not(:placeholder-shown):valid ~ .input__icon { color: var(--success); }
/* end login form animation */

/* dashboard menu animations */
.menu__item.isActive { 
  background: var(--linear-gradient); 
  font-weight: 500;
  z-index: -1; 
}

.menu__item::before { 
  background: var(--linear-gradient); 
  content: ''; 
  height: 100%;
  position: absolute; 
  left: 0; 
  top: 0; 
  width: 0; 
  z-index: -1;
}

.menu__item:not(.isActive):hover::before { width: 100%; }
/* end dashboard menu animations */

/* dashboard billing/faturas */
.title--border::before {
  bottom: 0;
  content: '';
  background-color: #C40000;
  height:0.2rem;
  position: absolute;
  left: 0;width:1rem;
} 

.circuito__informacoes.isActive{ padding: 1.5rem; }

.botao__fatura--icone.rotate{ transform: rotate(-90deg) }


/* end dashboard billing/faturas */

/* offcanvas */
.offcanvas__recebidas,
.offcanvas__efetuadas,
.test__menu {
  width: 100%;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.offcanvas__recebidas { 
  transform: translate(-130%, 0); 
}

.offcanvas__efetuadas { 
  display: none;
  transform: translate(130%, 0);
 }

.offcanvas__recebidas.isActive,
.offcanvas__efetuadas.isActive {
  display: block;
  height: max-content;
  opacity: 1;
  transform: translate(0);
}

.informarcoes--linha:nth-child(odd), 
.resumo__info:nth-child(odd) { background-color: var(--lightgray); }

.menu--area,
.navigator__menu,
.modal--height { height: max-content; }

.fixed__navigator { z-index: -1; }

.initial__navigator,
.fixed__navigator { width: max-content; }

.initial__navigator.isHidden,
.fixed__navigator {
  opacity: 0;
  transform: scale(0);
}

.fixed__navigator.isActive {
  opacity: 1;
  transform: scale(1);
  z-index: 100;
}

.register__modal.isActive,
.delete__blacklist.isActive,
.info__blacklist.isActive, 
.delete__modal.isActive,
.phone__modal.isActive,
.modal.isActive { display: flex; }

/* toggle buttons animation */
.switch {
  height: 1.5rem;
  width: 2.5rem;
}

.slider {
  box-shadow: inset 0 2px 2px #DFDFDF;
  transition: all 250ms ease;
}

.slider:before {
  background-color: #DFDFDF;
  content: "";
  height: 1rem;
  position: absolute;
  left: 0.1rem;
  top: 0.2rem;
  -webkit-transition: 400ms;
  transition: 400ms;
  width: 1rem;
}

.toggle__input:checked + .slider { 
  background-color: var(--lightgreen); 
  box-shadow: inset 0 2px 2px var(--lightgreen);
}

.toggle__input:checked + .slider:before {
  background-color: #FFF;
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.round:before { border-radius: 50%; }

/* end toggle buttons animation */

.toggle__mobile.isActive { transform: rotateY(180deg); }

.menu__mobile.isActive { display: block; }

body { background-color: #333; }
.loading { 
  position: fixed;
  float: left;
  top: 50%;
  left: 50%;
  height: 120px;
  padding: 0px;
  width: 160px;
  margin-top: -50px;
  margin-left: -70px;
  border-left:1px solid #fff;
  border-bottom:1px solid #fff;
  padding:10px;
  box-sizing:border-box;
}
@keyframes loading {
  0% { background-color: #cd0a00; }

  30% { background-color: #fa8a00; }
  50% { height: 100px; margin-top: 0px; }
  80% { background-color: #91d700;  }
  100% { background-color: #cd0a00; }
}
/*@-moz-keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}
@-o-keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}
@keyframes loading {
  50% { height: 100px; margin-top: 0px; }
}*/
@mixin inner() {
  height: 10px;
  width: 30px;
  background-color: #fff;
  display: inline-block;
  margin-top: 90px;
  -webkit-animation: loading 2.5s infinite;
  -moz-animation: loading 2.5s infinite;
  -o-animation: loading 2.5s infinite;
  animation: loading 2.5s infinite;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
 
}
@mixin loading() {
	@for $i from 1 through 10 {
		.loading-#{$i} { @include inner(); -webkit-animation-delay: $i/4+s; animation-delay: $i/4+s; }
	}
}
.loading { @include loading(); }
